import dayjs from 'dayjs';

interface VerbatimDetailProps {
  items?: any[]; // Update type as needed
}

function formatDate(date: string) {
  return dayjs(date).format('ddd D MMM YYYY');
}

const VerbatimDetail = ({ items }: VerbatimDetailProps) => {
  return (
    <div className="verbatim-detail">
      <dl className="dlist">
      {items.map((item) => (
        <div className="dlist__item">
          <dt key={item.date} className="dlist__dt">
            {formatDate(item.date)}
          </dt>
          <dd key={item.date} className="dlist__dd">
            {item.verbatim}
          </dd>
        </div>
      ))}
      </dl>
    </div>
  );
};

export { VerbatimDetail };
